<template>
  <div>
    <portal to="page-top-title">New Domain</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/domains" redirect="domains-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-input name="ip" :model="formData.ip" :min="3" required/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import Form from '@/modules/utils/Form'

export default {
  name: 'DomainsNew',
  metaInfo () {
    return { title: 'Domains | New' }
  },
  components: {
    BoxForm,
    FormGroupInput
  },
  data () {
    return {
      categories: [],
      shortcodes: [],
      macros: [],
      formData: {
        name: Form.types.input(),
        ip: Form.types.input()
      }
    }
  }
}
</script>
